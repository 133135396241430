<template>
  <modal-form
    v-slot="{ properties, errors, entity }"
    type="editPrinter"
    :options="{
      properties: {
        name: '',
        ipAddress: '',
        store: null,
        model: null,
      },
      postUri: '/printers',
      label: $t('printer.label')
    }"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" required />
    <fancy-input v-model="properties.ipAddress" :label="$t('general.field.ipAddress')" :error="errors.ipAddress" required />
    <simple-dropdown v-model="properties.model" :label="$t('printer.model.label')" required>
      <option v-for="(data, key) in printerModels" :key="key" :value="data">
        {{ data }}
      </option>
    </simple-dropdown>
    <multiselect
      v-if="entity['@id']"
      v-model="properties.store"
      :options="async (q) => loadStoreOptions(q, properties.store)"
      :loading="loadingStoreOptions"
      :error="errors.store"
      :form-label="$t('store.label')"
      :filter-results="false"
      searchable
    />
  </modal-form>
</template>
<script>
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import useLoadOptions from '@/hooks/api/loadOptions';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';

export default {
  name: 'EditPrinterModal',
  components: {
    SimpleDropdown,
    FancyInput,
    ModalForm,
    Multiselect,
  },
  setup() {
    const {
      loadOptions: loadStoreOptions,
      loading: loadingStoreOptions,
    } = useLoadOptions('/stores', 'name');

    return {
      loadStoreOptions,
      loadingStoreOptions,
      printerModels: [
        'EPOS2_TM_M30',
        'EPOS2_TM_M30II',
      ],
    };
  },
};
</script>
