<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('printer.label', 2)" />
      <button
        class="small"
        @click="$store.dispatch('modals/openModal', { modal: 'editPrinter', entity: {
          name: '',
          ipAddress: '',
          store: `/stores/${$route.params.storeId}`,
        } })"
      >
        +
      </button>
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      @click:edit="$store.dispatch('modals/openModal', { modal: 'editPrinter', entity: normalizeEntity($event) })"
      @click:delete="$store.dispatch('modals/openModal', { modal: 'deletePrinter', entity: $event })"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
    />

    <edit-printer-modal @update="loadEntities" />
    <delete-modal type="deletePrinter" entity-translation-key="printer" label="name" @update="loadEntities" />
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import EditRegisterModal from '@/components/entities/register/EditRegisterModal.vue';
import EditPrinterModal from '@/components/entities/printer/EditPrinterModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';

export default {
  name: 'PrinterIndexPage',
  components: {
    DeleteModal,
    EditPrinterModal,
    EditRegisterModal,
    EntityTable,
  },
  setup() {
    const route = useRoute();
    const { printer: fields } = useEntityFields();
    const {
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
    } = useLoadEntities('/printers', {
      sort: 'name',
      defaultParams: {
        store: route.params.storeId,
      },
    });

    const normalizeEntity = (entity) => ({
      ...entity,
      store: entity?.store?.['@id'],
    });

    return {
      fields,
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      normalizeEntity,
    };
  },
};
</script>
