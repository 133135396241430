<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('printerRule.label', 2)" />
      <button
        class="small"
        @click="$store.dispatch('modals/openModal', { modal: 'editPrinterRule', entity: {
          printer: null,
          productGroup: null,
          register: null,
        } })"
      >
        +
      </button>
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      @click:edit="$store.dispatch('modals/openModal', { modal: 'editPrinterRule', entity: normalizeEntity($event) })"
      @click:delete="$store.dispatch('modals/openModal', { modal: 'deletePrinterRule', entity: $event })"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
    />

    <edit-printer-rule-modal @update="loadEntities" />
    <delete-modal type="deletePrinterRule" entity-translation-key="printerRule" label="name" @update="loadEntities" />
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditPrinterRuleModal from '@/components/entities/printer/EditPrinterRuleModal.vue';

export default {
  name: 'PrinterIndexPage',
  components: {
    EditPrinterRuleModal,
    DeleteModal,
    EntityTable,
  },
  setup() {
    const route = useRoute();
    const { printerRule: fields } = useEntityFields();
    const {
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
    } = useLoadEntities('/printer_rules', {
      sort: 'printer.name',
      defaultParams: {
        'printer.store': route.params.storeId,
      },
    });

    const normalizeEntity = (entity) => ({
      ...entity,
      printer: entity?.printer?.['@id'],
      productGroup: entity?.productGroup?.['@id'],
      register: entity?.register?.['@id'],
    });

    return {
      fields,
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      normalizeEntity,
    };
  },
};
</script>
