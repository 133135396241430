<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editPrinterRule"
    :options="{
      properties: {
        printer: '',
        productGroup: '',
        register: null,
        blockRegisterOnFail: false,
      },
      postUri: '/printer_rules',
      label: $t('printerRule.label')
    }"
  >
    <multiselect
      v-model="properties.printer"
      :options="async (q) => loadPrinters(q, properties.printer)"
      :loading="loadingPrinters"
      :error="errors.printer"
      :form-label="$t('printer.label')"
      :filter-results="false"
      searchable
    />
    <multiselect
      v-model="properties.productGroup"
      :options="async (q) => loadProductGroups(q, properties.productGroup)"
      :loading="loadingProductGroups"
      :error="errors.productGroup"
      :form-label="$t('productGroup.label')"
      :filter-results="false"
      required
      searchable
    />
    <multiselect
      v-model="properties.register"
      :options="async (q) => loadRegisters(q, properties.register)"
      :loading="loadingRegisters"
      :error="errors.register"
      :form-label="$t('register.label')"
      :filter-results="false"
      :placeholder="$t('register.all')"
      searchable
    />
    <fancy-checkbox v-model="properties.blockRegisterOnFail" :label="$t('printerRule.blockRegisterOnFail')" :error="errors.blockRegisterOnFail" />
  </modal-form>
</template>
<script>
import { useRoute } from 'vue-router';
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import FancyCheckbox from '@/components/utils/forms/FancyCheckbox.vue';

export default {
  name: 'EditPrinterRuleModal',
  components: {
    FancyInput,
    ModalForm,
    Multiselect,
    FancyCheckbox,
  },
  setup() {
    const route = useRoute();
    const {
      loadOptions: loadPrinters,
      loading: loadingPrinters,
    } = useLoadOptions('/printers', 'name', {
      store: route.params.storeId,
    });
    const {
      loadOptions: loadProductGroups,
      loading: loadingProductGroups,
    } = useLoadOptions('/product_groups', 'name');
    const {
      loadOptions: loadRegisters,
      loading: loadingRegisters,
    } = useLoadOptions('/registers', 'name', {
      store: route.params.storeId,
    });

    return {
      loadPrinters,
      loadingPrinters,
      loadProductGroups,
      loadingProductGroups,
      loadRegisters,
      loadingRegisters,
    };
  },
};
</script>
